import React from 'react';
import { Container, Row, Col, Button, Nav, Navbar, NavItem, NavLink } from 'reactstrap';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Link } from "gatsby"
import axios from 'axios';
import museumIcon from "../images/museum.png"
import frameIcon from "../images/frame.png"


import AvNav from '../components/AvNavbar'
import AvGalleryDisplay from '../components/AvGalleryDisplay'
import AvContactFooter from '../components/AvContactFooter'
import '../components/index.css';

library.add(fab, fas)


//end class

class GalleryPage extends React.Component {

	navItemLinks = [
	  {location:'/',text:'Home'},
		{location:'#contactAnchor',text:'Contact'}
	];

	render() {
		return (
		<div id="galleryPage">
			<AvNav navItems={this.navItemLinks}/>
			<Container id="galleryContainer" fluid>
				<Row id="galleryHeaderRow" className="galleryH1" >
          <Col className="text-center">
            <h1>Projects: Past and Present</h1>
          </Col>
				</Row>
				<Row className="contentRow">
					<Col id="" >
						<AvGalleryDisplay displayType="page"/>
					</Col>
				</Row>
			</Container>
			<AvContactFooter />
		</div>
		);
	}
}

// ========================================


export default GalleryPage
